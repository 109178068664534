.header {
  position: sticky;
  width: 100%;
  min-width: 100%;

  padding-right: 20px;
  top: 0px;
  left: 0;

  background: #ffffff;
  transition: all 0.35s cubic-bezier(0.2, 0.8, 0.2, 1);
  transform: translateY(0px);
  backdrop-filter: blur(8px);

  border-radius: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.content {
  width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.contentLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  gap: 40px;
}

.naviagtionRow {
  display: flex;
  height: 100%;
  gap: 38px;
  align-items: center;
}
.gnb {
  height: 100%;
}
.gnb li {
  position: relative;
}

.gnb li .link {
  display: flex;
  align-items: center;
  height: 70px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #333;
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
}

span.link {
  cursor: default;
}

.gnb li .link:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0px;
  width: 70px;
  height: 4px;
  border-radius: 4px;
  margin-bottom: 0px;
  background: #fe6b37;
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  transform: scaleX(0);
  transform-origin: bottom center;
  margin-left: -35px;
}
.gnb li:hover .link:before {
  transform: scaleX(1);
}
.depth2 {
  position: absolute;
  top: -300px;
  z-index: 2;
  transition: 0s 0.2s ease-in-out;
  margin-left: 40px;
}
.gnb li .depth2:before {
  content: '';
  position: absolute;
  top: -11px;
  left: -90px;
  width: 180px;
  height: 12px;
}
.gnb li .depth2 ul {
  width: 180px;
  margin-left: -90px;
  padding: 6px 0;
  background: #fff;
  border-radius: 20px;
  box-shadow: 3.5px 6.1px 19px 0 rgba(43, 29, 72, 0.24);
  opacity: 0;
  transition: 0.2s ease-in-out;
}
.gnb li .depth2 ul li {
  float: none;
}
.gnb li .depth2 ul li + li {
  margin-left: 0;
  border-top: 1px solid #ddd;
}
.gnb li .depth2 ul li a {
  display: block;
  height: 51px;
  padding-top: 0;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  text-align: center;
  line-height: 50px;
}
.gnb li .depth2 ul li.on a,
.gnb li .depth2 ul li a:hover {
  color: #fe6b37;
}
.gnb li .depth2 ul li a:before {
  display: none !important;
}
.gnb li:hover .depth2 {
  top: 80px;
  transition: 0s ease-in-out;
}
.gnb li:hover .depth2 ul {
  opacity: 1;
}
.gnb li .depth2:hover {
  display: block;
}
.sign-area {
  display: inline-flex;
  gap: 20px;
  padding: 15px 0px;
  height: 100%;
}

.cs-area {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.btn-cs-center {
  display: inline-flex;
  align-items: center;

  position: relative;

  font-size: 24px;
  font-weight: 500;
  color: #000;
  line-height: 20px;
  vertical-align: top;
}

.btn-cs-icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  background: #e7e7e7;
}

.signButtons {
  display: inline-flex;
  align-items: center;
  height: 100%;
  gap: 10px;
}

.signInButton {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 27px;
  font-size: 18px;
  color: #000;
  line-height: 52px;
  transition: 0.2s ease-in-out;
  cursor: default;
  font-weight: 500;
  padding-right: 12px;
}
.signInButton:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 30px;
  margin: 0px auto;
}

.sign-area .btn + .btn {
  margin-left: 10px;
}
.sign-area .loginDetail {
  position: absolute;
  top: -300px;
  left: 50%;
  z-index: 2;
  width: 180px;
  margin-left: -90px;
  padding: 6px 0;
  background: #fff;
  border-radius: 20px;
  box-shadow: 3.5px 6.1px 19px 0 rgba(43, 29, 72, 0.24);
  opacity: 0;
  transition:
    opacity 0.2s ease-in-out,
    top 0s 0.3s ease-in-out;
}
.loginDetail a {
  display: block;
  height: 51px;
  padding-top: 0;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  text-align: center;
  line-height: 50px;
}
.loginDetail a + a {
  border-top: 1px solid #ddd;
}

.loginDetail a:hover {
  color: #fe6b37;
}
.signInButton:hover .loginDetail {
  top: 74px;
  opacity: 1;
  transition:
    opacity 0.2s ease-in-out,
    top 0s ease-in-out;
}

.demoButton {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  padding: 0px 24px;
  border-radius: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 52px;
  text-align: center;
  transition: 0.2s ease-in-out;
}
@keyframes slidePhrase {
  0% {
    transform: translateY(-40px);
  }
  35% {
    transform: translateY(-40px);
  }
  50% {
    transform: translateY(0px);
  }
  85% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(40px);
  }
}
.signUpButton {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  /* width: 215px; */
  color: white;
  border: 1px solid #fe6b37;
  background: #fe6b37;
  padding: 0px 24px;
  border-radius: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 52px;
  text-align: center;
  transition: 0.2s ease-in-out;
  transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  overflow: hidden;
}
