.logo-symbol,
.logo-letter {
  transition: all 0.4s cubic-bezier(0.2, 0.8, 0.25, 1);
}

.logo-symbol {
  opacity: 1;
  transform: translate(0px, 0px);
}
.logo-letter {
  transform: translate(0px, 0px);
}
.hide {
  opacity: 0;
  transform: translate(-15px, 0px) scale(0.6);
}
.enlarge {
  transform: translate(-20px, 0px) scale(1.3);
}
