.header {
  position: absolute;
}
.header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 52px;
  padding: 0 10px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;
}
.header .section:after {
  content: '';
  display: block;
  clear: both;
}
.header h1 {
  flex: 0 1 auto;
  width: 50px;
  height: 16px;
}
.header h1 a {
  display: block;
}
.header h1 img {
  display: block;
  width: 100%;
  height: 100%;
}
.header > .gnb {
  flex: 0 1 auto;
  margin: 0 8px 0 auto;
}
.header > .gnb li {
  position: relative;
}

.header > .logoImage > img {
  min-width: 0;
  flex-shrink: 1;
}

.header .gnb .btn-toggle {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  transition: color 0.2s ease-in-out;
  padding: 0px;
  word-break: keep-all;
}

.header .gnb .depth2 {
  display: none;
  position: absolute;
  top: 33px;
  right: calc(50% - 60px);
  width: 91px;
  padding: 15px 0 20px 14px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 6px 0 rgba(38, 29, 72, 0.25);
}
.header .gnb .depth2 li + li {
  margin-top: 9px;
}
.header .gnb .depth2 a {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #3e3e40;
  line-height: 20px;
  transition: color 0.2s ease-in-out;
}
.header .gnb .depth2 a.on {
  -webkit-tap-highlight-color: transparent;
  font-weight: bold;
  color: #e95a0c;
}
.header .sign-area {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
}
.header .sign-area:after {
  content: '';
  display: block;
  clear: both;
}
.header .sign-area .btn-login-wrap {
  position: relative;
  margin-right: 4px;
}
.header .sign-area .btn-login {
  width: 60px;
  height: 28px;
  border: 1px solid #666;
  border-radius: 14px;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  transition: 0.2s ease-in-out;
}
.header .sign-area .btn-login.on,
.header .sign-area .btn-login.hover {
  border-color: #e95a0c;
  color: #e95a0c;
}
.header .sign-area .btn-login-wrap .login-detail {
  display: none;
  position: absolute;
  top: 33px;
  left: 0;
  width: 105px;
  padding: 15px 0 20px 14px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 6px 0 rgba(38, 29, 72, 0.25);
}
.header .sign-area .btn-login-wrap .login-detail a {
  display: block;
  font-size: 14px;
  color: #3e3e40;
  line-height: 20px;
  transition: color 0.2s ease-in-out;
}
.header .sign-area .btn-login-wrap .login-detail a + a {
  margin-top: 9px;
}
.header .sign-area .btn-login-wrap .login-detail a.on,
.header .sign-area .btn-login-wrap .login-detail a:hover {
  -webkit-tap-highlight-color: transparent;
  font-weight: bold;
  color: #e95a0c;
}
.header .sign-area .btn-join {
  flex: 0 1 auto;
  display: inline-flex;
  align-items: center;
  /* width: 90px; */
  height: 28px;
  padding: 0px 16px;
  /* padding-top: 5px; */
  background: #402578;
  border-radius: 14px;
  font-size: 12px;
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 18px;
  transition: 0.2s ease-in-out;
}
.header .sign-area .btn-join.hover {
  background: #e95a0c;
}
.header .sign-area .btn-cs-center {
  flex: 0 1 auto;
  width: 32px;
  height: 32px;
  margin: 0 0 0 6px;
  background-size: 32px 32px;
  border-radius: 32px;
  text-indent: -9999em;
  transition: 0.2s ease-in-out;
}
.header .sign-area .btn-cs-center.hover {
  background-image: url('/public/assets/mobile/images/icon/icon_cs2.svg');
}
.header.sub {
  height: 52px;
  padding-top: 11px;
  background: #ff663f;
  border-bottom: 0;
}
.header.sub p {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 29px;
}

.services {
  display: block;
}
